body,html {
    max-width: unset !important;
    min-height: 100vh !important;
    min-width: 100vw !important;
    overscroll-behavior-y: contain;
    padding: 0 !important;
}

main {
    background-color: black;
    text-align: center;

    .application-wrap {
        backface-visibility: hidden;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        max-width: 100%;
        min-height: 100dvh;
        position: relative;

        @media (min-width: 1280px) {
            .container {
                max-width: 1200px;
            }
        }

        @media (min-width: 960px) {
            .container {
                max-width: 900px;
            }
        }

        .container-fluid {
            max-width: 100%;
        }

        .container {
            margin-left: auto;
            margin-right: auto;
            padding: 16px;
            width: 100%;

            .window-container {
                height: 100vh;
                width: 100vw;
            }
        }
    }
}

.window-container {
    display: flex;
    flex-direction: column;
    height: inherit;
    position: relative;
    transition: .3s cubic-bezier(.25,.8,.5,1);

    .window-item {
        height: 100vh;
        width: 100vw;
    }
}

.wedding-main-section {
    flex: 1 0 auto;
    max-width: 100%;
    padding: 0px 0px 0px 0px;
    transition: .2s cubic-bezier(.4,0,.2,1);

    .wedding-container {
        height: 100vh !important;
        overflow-x: auto;
        overflow-y: auto;
        position: relative;
        scroll-behavior: smooth;
        width: 100vw !important;

        display: flex;
        align-items: center;
        justify-content: center;

        .wedding-container-element {
            margin: auto;
            position: relative;

            img {
                display: block;

                &.main-img {
                    max-height: 100vh;
                    max-width: 100vw;
                }
            }

            .wedding-element {
                display: flex;
                overflow: auto;
                overflow: visible;
                position: absolute;

                &#item1 {
                    height: auto;
                    width: 58%;
                    left: 0;
                    bottom: 0;
                    pointer-events: all;
                }

                &#item2 {
                    height: auto;
                    width: 38%;
                    right: 0;
                    bottom: 16%;
                    pointer-events: all;
                }

                &#item3 {
                    height: auto;
                    width: 25%;
                    right: 0%;
                    bottom: 13%;
                    pointer-events: all;
                }

                &#item4 {
                    height: auto;
                    width: 12%;
                    left: 67%;
                    top: 53%;
                    pointer-events: all;
                }

                &#item5{
                    height: auto;
                    width: 24%;
                    right: 4.6818%;
                    bottom: 29.0712%;
                    pointer-events: all;
                }

                &#item6{
                    height: auto;
                    width: 59%;
                    right: 0;
                    bottom: 0;
                    pointer-events: all;
                }

                &#item7 {
                    height: auto;
                    width: 39%;
                    left: 5%;
                    bottom: 0;
                    pointer-events: all;
                }

                &#item8 {
                    height: auto;
                    width: 35%;
                    right: 30%;
                    bottom: 0;
                    pointer-events: all;
                }

                &#item9 {
                    height: auto;
                    width: 38%;
                    right: 25%;
                    bottom: 0;
                    pointer-events: all;
                }

                &#item10 {
                    height: auto;
                    width: 7%;
                    left: 3.6818%;
                    top: 2.0712%;
                    pointer-events: all;
                }

                &#item11 {
                    height: auto;
                    width: 7%;
                    left: 11.6818%;
                    top: 2.0712%;
                    pointer-events: all;
                }

            }
        }
    }
}

.active-element {
    filter:
            drop-shadow(1.5px 0 0 #cae557)
            drop-shadow(0 1.5px 0 #cae557)
            drop-shadow(-1.5px 0 0 #cae557)
            drop-shadow(0 -1.5px 0 #cae557)
}

.loading-container-suspense {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed; /* Fixed position to cover the whole screen */
  top: 0;
  left: 0;
  z-index: 9999; /* High z-index to overlay on other content */
  background-color: #fff
}

.loading-container-suspense img {
  width: 75px;
  height: 75px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main-img {
  transition: opacity 0.5s ease-in-out; /* Adjust the duration as needed */
}

.bg-transition {
  opacity: 0.5; /* Adjust the opacity as needed */
}